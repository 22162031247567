import { html, LitElement } from "lit"
import { createRef, ref } from "lit/directives/ref.js"
import { gtag, placeholders } from "#js/components/utils"
import { msg, str, updateWhenLocaleChanges } from "@lit/localize"
import { fetchJSON } from "#js/components/http"
import { setLocale } from "#js/components/lit-i18n"

export class OfferSlider extends LitElement {
  static get properties() {
    return {
      id: { type: String },
      apiUrl: { type: String },
      title: { type: String },
      listUrl: { type: String },
      listTitle: { type: String },
    }
  }

  constructor() {
    super()
    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)
  }

  sliderRef = createRef()
  offers = undefined

  async firstUpdated() {
    this.offers = await this.fetchOffer()
    this.requestUpdate()
  }

  render() {
    return html`
      <link rel="stylesheet" href="${globalThis.styleFilePath}"/>
      <section>
        <h2>${this.title}</h2>
        <div class="layout">
          <div class="slider-grid" ${ref(this.sliderRef)}>
            ${
      this.offers
        ? [...this.renderOffers(this.offers, this.id, this.title)]
        : placeholders(4)
    }
          </div>
          ${this.getListUrlLink()}
        </div>
      </section>
    `
  }

  *renderOffers(offers, promotionId, promotionName) {
    for (let i = 0; i < 4; i++) {
      yield html`
          <offer-slider-card .offer="${offers[i]}"
                             sliderPosition="${i}"
                             promotionId="${promotionId}"
                             promotionName="${promotionName}"
          >
          </offer-slider-card>
        `
    }
  }

  getListUrlLink() {
    if (this.listUrl) {
      return html`
        <div class="button__group button__group--center">
          <a href="${this.listUrl}"
             @click="${(event) => this.sendClickSliderShowAll(event, this.title)}"
             class="button button--filled">
            ${this.listTitle ? this.listTitle : msg(str`show all`)}
          </a>
        </div>
      `
    }
  }

  async fetchOffer() {
    const url = new URL(this.apiUrl, globalThis.location.origin)
    url.searchParams.append("picture_desktop", "3")
    let offers = await fetchJSON(url, {}, [])
    if (Object.prototype.hasOwnProperty.call(offers, "results")) {
      offers = offers.results.slice(0, 16)
    }
    if (!offers.length) {
      this.remove()
      return []
    } else {
      return offers
    }
  }

  sendClickSliderShowAll(event, title) {
    gtag("event", "click_slider_show_all", {
      slider_show_all_url: event.target.href,
      slider_title: title,
    })
  }
}

globalThis.customElements.define("offer-slider", OfferSlider)
